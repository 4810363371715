<template>
  <div>
    <v-modal-messages-insert v-model="dialogInsert"></v-modal-messages-insert>

    <v-page-title title="Messages">
      <v-btn 
        elevation="0" 
        color="blue-grey darken-4" 
        small 
        dark 
        @click="dialogInsert = true"
      >
        <v-icon left small>mdi-plus</v-icon>
        New Message
      </v-btn>
    </v-page-title>

    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row dense>
          <v-col>
            <v-loader v-if="!messages"></v-loader>
            <v-alert light color="grey lighten-4" class="text-center" v-else-if="messages.length == 0">No message yet.</v-alert>
            <div class="hounddog-table" v-else>
              <v-data-table
                :headers="headers" 
                :items="messages" 
                item-key="id"
                :items-per-page=10
                sort-by="created_at"
                :sort-desc="true"
                no-data-text="Loading..."
                elevation="0"
              >
                <template v-slot:item.content="{ item }">
                  {{item.content}}
                </template>
                <template v-slot:item.created_at="{ item }">
                  <b>{{ $date.fullDateTime(item.created_at) }}</b>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-item-group class="v-btn-toggle" dense>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon v-bind="attrs" v-on="on" dark @click="deleteDialog(item)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete this</span>
                    </v-tooltip>
                  </v-item-group>
                </template>
              </v-data-table>
            </div>
            <v-dialog v-model="dialogDelete" max-width="500px" persistent>
              <v-card v-if="editForm.editedIndex > -1">
                <v-card-title>
                  <span class="text-h5">Delete Message</span>
                  <v-btn small dark fab absolute right text @click="closeDelete">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                  <b>Are you sure you want to delete this?</b>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn x-large dark color="blue-grey darken-4" @click="deleteItem">Yes</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Messages.vue',
    data() {
      return {
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        headers: [
          { text: "Messages", value: "content", width: '60%', align: 'left', divider: true },
          { text: "Date", value: "created_at", width: '30%', align: 'center', divider: true },
          { text: "Actions", value: "actions", width: '10%', align: 'center', divider: true },
        ],
        editForm:{
          editedIndex: -1
        }
      }
    },
    computed: {
      messages(){
        return this.$store.getters['messages/all'];
      }
    },
    methods:{
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.resetFields();
        })
      },
      resetFields(){
        this.editForm.editedIndex = -1;
        this.editForm.fields = {
          content: '',
          link: '',
          id: ''
        }
      },
      deleteDialog (item) {
        this.editForm.editedIndex = this.messages.indexOf(item)
        this.editForm.fields = Object.assign({}, item)
        this.dialogDelete = true;
      },
      editItem(item){
        this.editForm.editedIndex = this.messages.indexOf(item);
        this.editForm.fields = Object.assign({}, item);
      },
      deleteItem(){
        let indexField = {'index': this.editForm.editedIndex};
        let fields = { ...this.editForm.fields, ...indexField};
        this.$store.dispatch('messages/delete', fields);
        this.closeDelete();
      }
    }
  }
</script>

<style lang="scss">
</style>